<template>
  <AppLoading :label="label" />
</template>

<script>
import px from 'vue-types'

import AppLoading from './Loading.vue'
export default {
  name: 'AppSubmitting',
  components: { AppLoading },
  props: {
    label: px.string.def('Submitting'),
  },
}
</script>
