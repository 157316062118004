<template>
  <BAlert show variant="success" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>

    <template #default>
      <BIconCheckCircle class="mr-2" />
      <slot />
    </template>
  </BAlert>
</template>

<script>
import { BAlert, BIconCheckCircle } from 'bootstrap-vue'

export default {
  name: 'SuccessAlert',
  components: {
    BAlert,
    BIconCheckCircle,
  },
}
</script>

<style scoped></style>
