<template>
  <form @submit.prevent="onSubmit" @reset.prevent="onReset">
    <LoadingController :loading="loading" :error="error" @retry="retry">
      <template #loading>
        <slot name="loading">
          <AppSubmitting />
        </slot>
      </template>

      <template #after-error="{ attributes: { error } }">
        <slot :error="error" />
      </template>

      <template #default>
        <slot v-if="submitted" name="submitted" :result="result">
          <SuccessAlert>
            <slot name="success" :result="result"> Form submitted successfully </slot>
          </SuccessAlert>
        </slot>
        <slot v-if="!submitted || !clearOnSubmit" />
      </template>
    </LoadingController>
  </form>
</template>

<script>
import px from 'vue-types'

import axios_instance from '../../axios.js'
import AppSubmitting from '../generic/Submitting.vue'
import SuccessAlert from '../generic/SuccessAlert.vue'

import LoadingController from './LoadingController.vue'
export default {
  name: 'FormController',
  components: { SuccessAlert, LoadingController, AppSubmitting },
  props: {
    submit: px.func.isRequired,
    valid: px.bool.def(true),
    clearOnSubmit: px.bool.def(true),
  },
  data: () => ({
    error: null,
    loading: false,
    result: null,
    submitted: false,
  }),
  methods: {
    async onSubmit() {
      if (!this.valid) {
        return
      }
      this.loading = true
      try {
        this.error = null
        this.result = await this.submit()
        this.submitted = true
      } catch (e) {
        this.error = axios_instance.extractErrorMessage(e)
      } finally {
        this.loading = false
      }
    },
    onReset() {
      this.$emit('reset')
    },
    retry() {
      this.onSubmit()
    },
  },
}
</script>

<style scoped></style>
